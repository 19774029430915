import React from 'react';
import clsx from 'clsx';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Service from './Service';
import useServiceStyles from '../hooks/useServiceStyles';

const useStyles = makeStyles(theme => ({
  root: {},
  typeGroup: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 1),
    borderRadius: theme.shape.borderRadius,
  },
}));

const Services = ({ groups = {}, className, ...props }) => {
  const classes = useStyles();
  const { classes: srvClasses } = useServiceStyles();

  const { t } = useTranslation();

  // t('hpc summary')
  // t('hpc services')
  // t('cloud summary')
  // t('cloud services')
  // t('storage summary')
  // t('storage services')

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      {Object.entries(groups).map(([type, { summary, services: srvs = [] }]) => (
        <Box
          key={type}
          id={type}
          className={clsx(
            srvClasses[`${type}Dim`],
            classes.typeGroup,
          )}
        >
          <Grid container spacing={2}>
            <Grid item md={4}>
              {Boolean(summary) && (
                <Typography variant="h2">{t(`${type} summary`)}</Typography>
              )}
            </Grid>

            <Grid item md={8}>
              {Boolean(srvs.length) && (
                <Typography variant="h2">{t(`${type} services`)}</Typography>
              )}
            </Grid>

            <Grid item md={4}>
              {Boolean(summary) && (
                <Service data={summary} height={600} />
              )}
            </Grid>

            <Grid item md={8} container spacing={1}>
              {srvs.map(service => (
                <Grid key={service.name} item md={6}><Service data={service} /></Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default Services;
