import React from 'react';
import { useTranslation } from 'react-i18next';

import EditIcon from '@material-ui/icons/Edit';

import Link from './Link';
import useSiteMetadata from '../hooks/useSiteMetadata';

const EditSource = ({ sourcePath = '', label, icon = true, doc = false }) => {
  const { t } = useTranslation();
  const {
    remoteFileRoot = '',
    remoteDocRoot = '',
  } = useSiteMetadata();

  const fileRoot = doc ? remoteDocRoot : remoteFileRoot;

  return (
    <Link to={`${fileRoot}/${sourcePath}`}>
      {t(label || 'Modifier ce document')}
      {Boolean(icon)
        && <EditIcon fontSize="small" style={{ verticalAlign: 'middle' }} />}
    </Link>
  );
};

export default EditSource;
