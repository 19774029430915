import React from 'react';
import clsx from 'clsx';

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-material-ui-components';
import { GoLink } from '@react-icons/all-files/go/GoLink';

import EditSource from '../components/EditSource';
import Layout from '../components/Layout';
import Link from '../components/Link';

import useCorrectLanguage from '../hooks/useCorrectLanguage';

import SimpleMap from '../components/SimpleMap';

import Services from '../components/Services';
import useServiceStyles from '../hooks/useServiceStyles';

const useStyles = makeStyles(theme => ({
  quicknav: {
    marginBottom: theme.spacing(1),
  },
}));

const PlatformPage = ({
  data: {
    file: { internal: { content }, relativePath },
  },
  pageContext: { language },
}) => {
  useCorrectLanguage(language);
  const platformJson = JSON.parse(content);
  const { t } = useTranslation();
  const { classes: srvClasses } = useServiceStyles();
  const classes = useStyles();

  const jsonEditorPrefill = {
    data: platformJson,
    schema: 'platform',
    filename: relativePath,
  };

  const summaries = platformJson?.['Provider services overall summary'] || [];
  const services = platformJson?.Services || [];

  const byTypes = [summaries, services].flat().reduce((acc, curr) => {
    const { 'Service type': serviceType = 'n/a', name } = curr;
    if (!acc[serviceType]) {
      acc[serviceType] = { services: [] };
    }

    if (name) {
      acc[serviceType].services.push(curr);
    } else {
      acc[serviceType].summary = curr;
    }

    return acc;
  }, {});

  return (
    <Layout translationsAvailable>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Link prefix to="/oad">‹ {t('Retour à la recherche')}</Link>
        </Grid>

        <Grid item md={6} align="right">
          Modifier cette plateforme{' '}
          <EditSource label="sur Gitlab" sourcePath={`src/data/${relativePath}`} icon={false} />
          <br />
          ou{' '}
          <Link to="/form" state={jsonEditorPrefill}>{t('dans l\'aide à la saisie')}</Link>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={7}>
          <Grid container>
            <Grid item>
              <Typography variant="h1">{platformJson.name}</Typography>
              <Typography variant="subtitle1">{platformJson.institute}, {platformJson.location}</Typography>
            </Grid>

            <Grid item xs={2} style={{ marginLeft: 'auto', textAlign: 'right' }}>
              {Object.keys(byTypes).map(serviceType => (
                <React.Fragment key={serviceType}>
                  <Button
                    to={`#${serviceType}`}
                    variant="contained"
                    className={clsx(srvClasses[serviceType], classes.quicknav)}
                    size="small"
                  >
                    {serviceType}
                  </Button>
                  {' '}
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Typography variant="body2" style={{ marginTop: '2rem', whiteSpace: 'pre-wrap' }}>
            {platformJson.fullDescription}
          </Typography>
        </Grid>

        <Grid item md={4}>
          <SimpleMap position={platformJson.coordinates} zoom={8} />

          <Typography variant="h2">{t('Contact')}</Typography>

          <Typography variant="body1">
            {platformJson.contactName}
            <br />
            <a href={`mailto:${platformJson.contactAddress}`}>{platformJson.contactAddress}</a>
          </Typography>

          <Typography variant="body1">
            <a href={platformJson.url}><GoLink /> {t('Site web')}</a>
          </Typography>
        </Grid>
      </Grid>

      <Divider variant="middle" style={{ margin: '2rem' }} />

      <Services groups={byTypes} />
    </Layout>
  );
};

export default PlatformPage;

export const pageQuery = graphql`
  query($name: String) {
    file(
      sourceInstanceName: { eq: "data-file" }
      relativeDirectory: { eq: "platforms" }
      name: { eq: $name }
    ) {
      name
      relativePath
      internal {
        content
      }
    }
  }
`;
