import React from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LexiqueIcon from './LexiqueIcon';
import { flattenService } from '../hooks/useServices';
import useServiceStyles from '../hooks/useServiceStyles';
import useDisplayFields from '../hooks/useDisplayFields';
import { unAbbr } from '../helpers';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    maxHeight: ({ height }) => height,
    overflow: 'auto',
  },

  readmoreWrapper: {
    textAlign: 'right',
  },
  readmore: {
    textTransform: 'none',
  },

  title: {
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: theme.spacing(2),
  },
  table: {
    marginBottom: theme.spacing(2),
  },
  cell: {
    borderColor: 'rgba(0, 0, 0, .1)',
  },
}));

const enhanceValue = value => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }

  return value;
};

const Service = ({
  data: { 'Service type': serviceType, name, 'Short description': desc, ...data } = {},
  height = 400,
}) => {
  const classes = useStyles({ height });
  const { classes: srvClasses } = useServiceStyles();

  const { service: { highlight: { fields: highlight = [] } = {} } = {} } = useDisplayFields() || {};

  const [open, setOpen] = React.useState(false);

  const asItem = flattenService(data);
  const service = {
    serviceType,
    name,
    ...data,
    ...asItem,
  };

  return (
    <Paper className={clsx(classes.paper, srvClasses[serviceType])}>
      {(name || desc) && (
        <Box className={classes.intro}>
          {name && (
            <Typography variant="h3">{name}</Typography>
          )}

          {desc && (
            <Typography variant="body1">{desc}</Typography>
          )}
        </Box>
      )}

      <Table size="small" className={classes.table}>
        <TableBody>
          {highlight.map(({ field }) => (typeof service[field] !== 'undefined') && (
            <TableRow key={field}>
              <TableCell component="th" className={classes.cell}>
                {unAbbr(field)}
                <LexiqueIcon direct word={field} target="_blank" />
              </TableCell>

              <TableCell align="center" className={classes.cell}>
                {enhanceValue(service[field])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box className={classes.readmoreWrapper}>
        <Button
          variant="text"
          onClick={() => setOpen(s => !s)}
          className={classes.readmore}
        >
          Tous les critères
        </Button>
      </Box>

      {open && Object.entries(data).map(([title, content]) => (
        <Box key={title}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>

          {typeof content === 'string' && (
            <Typography variant="body2">
              {content}
            </Typography>
          )}

          {typeof content === 'object' && (
            <Table size="small" className={classes.table}>
              <TableBody>
                {Object.entries(content).map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" className={classes.cell}>
                      {label}<LexiqueIcon direct word={label} />
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {enhanceValue(value)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      ))}
    </Paper>
  );
};

export default Service;
