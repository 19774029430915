import React from 'react';

import { CircleMarker } from 'react-leaflet';

import Map from './Map';

const SimpleMap = ({ position, ...rest }) => (
  <Map center={position} {...rest}>
    {position && (
      <CircleMarker center={position} />
    )}
  </Map>
);

export default SimpleMap;
